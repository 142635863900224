import { Box, Button, Typography } from "@material-ui/core";
import Link from "next/link";

const SplashError = ({ label }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    flexGrow={1}
    height="100%"
  >
    <Box>
      <Typography paragraph color="textSecondary" variant="h4">
        {label}
      </Typography>
      <Typography paragraph variant="h1" color="primary">
        :(
      </Typography>
      <Link href="/" passHref>
        <Button variant="outlined">
          Go Back
        </Button>
      </Link>
    </Box>
  </Box>
);

export default SplashError;
